<template>
	<div class="rules">
		<base-navigation-bar
			:isLeft="true"
			title="活动规则"
			color="#000000"
			@handleBack="handleBack"
			returnArrow="https://hopemall.oss-cn-hangzhou.aliyuncs.com/suixintao/memberBenefits/returnArrow.png"
		/>
		<div class="content">
			1.活动内容：</br>
			订购随心淘会员，19.9元/月包含每30天可选1款app会员权益，到期前24小时内将自动扣费续订。</br>
			例如张三在2023年3月15日 10:56分订购了随心淘会员，那么在2023年3月15日
			10:56分～2023年4月13日
			10:55期间，张三可任选1款会员权益。且若张三在2023年4月12日
			10:55前未退订服务，那么在2023年4月12日 10:55～2023年4月13日
			10:55期间将自动扣费下一个30天计费周期的费用。</br>
			2.生失效方式：</br>
			订购立即生效，当前计费周期费用（30天）一次性扣除，下一个计费周期自动续订，退订需在到期前24小时。</br>
			3.会员权益领取：</br>
			会员权益每个计费周期（30天）限领1次，逾期作废。领取权益可关注【秋末权益管家】微信公众号，进入【会员中心】登陆手机号后领取</br>
			退订用户需在会员权益到期前24小时联系取消，在下一个计费周期生效，且取消后3个月内不能再次订购。</br>
			5.退订方式</br>
			支付宝：【我的】-【设置】-【支付设置】-【免密支付/自动扣款】，选择随心淘取消订阅</br>
			微信：【我的】-【服务】-【钱包】-【支付设置】-【自动续费】，选择随心淘取消订阅</br>
			6.会员权益范围：</br>
			包括爱奇艺月卡、腾讯视频月卡、腾讯体育月卡、优酷月卡、芒果月卡、迅雷月卡、哔哩哔哩月卡、PPTV月卡、喜马拉雅月卡、QQ音乐月卡、网易云音乐、酷我音乐月卡、蜻蜓FM月卡等权益，权益内容可能有调整，请以领取页面为准。</br>
			7.如对活动有疑问或更多需求，请咨询在线客服或拨打4001189599。</br>
		</div>
	</div>
</template>

<script>
import BaseNavigationBar from "@/components/BaseNavigationBar/BaseNavigationBar.vue";
export default {
	components: {
		BaseNavigationBar,
	},
	data() {
		return {
		};
	},
	methods: {
		handleBack() {
		  this.$router.go(-1);
		}
	}
}
</script>

<style lang="scss">
.rules {
		background-color: #ffffff;

	.content {
		color: #333333;
		line-height: 50px;
		background-color: #fff;
		padding: 25px 36px 100px 24px;
		font-size: 28px;
		margin-top: 2px;
	}
}
</style>
